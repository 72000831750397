const shouldIgnoreError = (error) => {
  const ignoredErrors = [
    'Minified React error #418', // This is a warning from React
    'Minified React error #423', // This is a warning from React
    'Minified React error #425', // This is a warning from React
    "Messaging: This browser doesn't support the API", // This is a warning from Firebase
    'Connection to Indexed Database server lost', // This is a warning from IndexedDB
    'ResizeObserver', // This is a warning from ResizeObserver at Library
  ];

  const isIgnored = ignoredErrors.some((ignoredError) => error.message && error.message.includes(ignoredError));
  if (isIgnored) {
    return true;
  }

  return false;
};

const NewRelic = {
  script: `https://assemblrworld-asset.s3.ap-southeast-1.amazonaws.com/scripts/newrelic/${process.env.NODE_ENV === 'production' ? 'studio' : 'dev'
    }.js`,
  setUserId: (uid) => {
    if (typeof window !== 'undefined' && window.newrelic && typeof window.newrelic.setUserId === 'function') {
      if (window.newrelic.userId !== uid) {
        console.log('Set user id to new relic', uid);
        window.newrelic.setUserId(uid);
        window.newrelic.userId = uid;
      }
    }
  },
  setErrorHandler: () => {
    if (typeof window !== 'undefined' && window.newrelic && typeof window.newrelic.setErrorHandler === 'function') {
      window.newrelic.setErrorHandler((shouldIgnoreError));
    }
  },
};

export default NewRelic;
